<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 19 20"
  >
    <path
      fill="currentColor"
      d="M15.1 3.66a1 1 0 1 0 0 2v-2Zm-9.4 8.77a1 1 0 0 0-2 0h2Zm-1 .3h-1 1Zm9.6 1.6.71-.7-.3-.3h-.41v1Zm3.2 3.23-.71.7L18.5 20v-2.43h-1Zm-1.6-12.9v-1 1ZM1.5 14.33h-1v2.5l1.72-1.8-.72-.7Zm3.56-2.27a1 1 0 0 0-1.44-1.39l1.44 1.39Zm-1.36.37v.3h2v-.3h-2Zm0 .3c0 .68.27 1.34.76 1.84l1.42-1.41a.62.62 0 0 1-.18-.44h-2Zm.76 1.84a2.6 2.6 0 0 0 1.84.76v-2a.6.6 0 0 1-.42-.17l-1.42 1.4Zm1.84.76h8v-2h-8v2Zm7.29-.3 3.2 3.23 1.42-1.4-3.2-3.23-1.42 1.4Zm4.91 2.53V6.26h-2v11.3h2Zm0-11.3c0-.68-.27-1.34-.76-1.84l-1.42 1.41c.11.12.18.27.18.44h2Zm-.76-1.84a2.6 2.6 0 0 0-1.84-.76v2c.16 0 .3.06.42.17l1.42-1.4Zm-1.84-.76h-.8v2h.8v-2ZM.5 9.49v4.84h2V9.5h-2Zm1.72 5.53 2.84-2.96-1.44-1.39-2.84 2.97 1.44 1.38Z"
    />
    <path
      fill="currentColor"
      d="M4.34 10.37a1 1 0 1 0 0 2v-2ZM3.1 2.56h9.68v-2H3.11v2Zm9.68 0c.33 0 .61.27.61.63h2A2.62 2.62 0 0 0 12.8.56v2Zm.61.63v6.54h2V3.2h-2Zm0 6.54c0 .36-.28.64-.61.64v2c1.46 0 2.61-1.2 2.61-2.64h-2Zm-10.9 0V3.2h-2v6.54h2Zm0-6.54c0-.36.29-.63.61-.63v-2A2.62 2.62 0 0 0 .5 3.19h2Zm10.29 7.18H4.34v2h8.45v-2Z"
    />
    <circle
      cx="5.63"
      cy="6.72"
      r=".52"
      fill="currentColor"
    />
    <circle
      cx="8.21"
      cy="6.72"
      r=".52"
      fill="currentColor"
    />
    <circle
      cx="10.79"
      cy="6.72"
      r=".52"
      fill="currentColor"
    />
  </svg>
</template>
